import SVG_ICONS_SHARED from '../../images/_shared/svg';
import ROUTES from '../../constants/routes';
import { OpenFormContactData } from '../_Shared/YourIdea/types';

export const contactUsData: OpenFormContactData[] = [
  {
    link: ROUTES.PHONE,
    icon: SVG_ICONS_SHARED.PHONE,
    text: '+38(050)658-75-05',
  },
  {
    link: ROUTES.MAIL,
    icon: SVG_ICONS_SHARED.ENVELOPE,
    text: 'hello@lunka.tech',
  },
  {
    link: ROUTES.LINKEDIN,
    icon: SVG_ICONS_SHARED.LINKEDIN,
    text: 'company/lunka',
    target: '_blank',
  },
  {
    link: ROUTES.INSTAGRAM,
    icon: SVG_ICONS_SHARED.INSTAGRAM,
    text: '@lunka.tech',
    target: '_blank',
  },
  {
    link: ROUTES.CLUTCH,
    icon: SVG_ICONS_SHARED.CLUTCH,
    text: 'Clutch',
    target: '_blank',
  },
  {
    link: ROUTES.BEHANCE,
    icon: SVG_ICONS_SHARED.BEHANCE,
    text: 'Behance',
    target: '_blank',
  },
  {
    link: ROUTES.FACEBOOK,
    icon: SVG_ICONS_SHARED.FACEBOOK,
    text: 'Facebook',
    target: '_blank',
  },
];
