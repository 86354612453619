import * as React from 'react';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import ContactUsContent from '../components/ContactUs/ContactUs';
import { contactUsDescription } from '../seoData/descriptions';
import { contactUsTitle } from '../seoData/titles';
import { HomeData } from '../components/Home/types';
import { graphql } from 'gatsby';

export const query = graphql`
  query ContactUsPageQuery {
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const ContactUs = ({ data }: HomeData) => {
  const { allSanityFooter } = data;

  return (
    <PageLayout footerData={allSanityFooter} title={contactUsTitle} description={contactUsDescription}>
      <ContactUsContent />
    </PageLayout>
  );
};

export default ContactUs;
