import * as React from 'react';
import './ContactUs.scss';
import { contactUsData } from './defaultData';
import OpenForm from '../_Shared/OpenForm/OpenForm';
import { useEffect, useState } from 'react';
import BreadCrumbs from '../_Shared/Breadcrumbs/Breadcrumbs';

const ContactUsContent = () => {
  const [isBiggerHeight, setIsBiggerHeight] = useState<boolean>(false);

  useEffect(() => {
    setIsBiggerHeight(document.documentElement.scrollHeight >= 1500);
  }, []);

  return (
    <section className="contact">
      <div className="contact__breadCrumbs">
        <BreadCrumbs isDark />
      </div>
      <div className="contact__info">
        <div className="contact__container" style={{ marginTop: isBiggerHeight ? '52px' : 0 }}>
          <div className="contact__information">
            <h1>Get in touch</h1>
            <p>
              If you are looking for support with your organisation’s sustainability ambitions or you have any
              questions, our team is here to help
            </p>
            {contactUsData.map(({ link, icon, text, target }, index: number) => {
              return (
                <a key={index} className="contactList" target={target} href={link}>
                  <img src={icon} alt="contact" />
                  <span>{text}</span>
                </a>
              );
            })}
          </div>
          <OpenForm />
        </div>
      </div>
    </section>
  );
};

export default ContactUsContent;
